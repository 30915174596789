var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_vm._l((_vm.form.getFieldValue('keys')),function(k,index){return _c('a-form-item',_vm._b({key:k,attrs:{"label":index === 0 ? 'Passengers' : '',"required":false}},'a-form-item',index === 0 ? _vm.formItemLayout : _vm.formItemLayoutWithOutLabel,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
				("names[" + k + "]"),
				{
					validateTrigger: ['change', 'blur'],
					rules: [
						{
							required: true,
							whitespace: true,
							message: 'Please input passenger\'s name or delete this field.',
						} ],
				} ]),expression:"[\n\t\t\t\t`names[${k}]`,\n\t\t\t\t{\n\t\t\t\t\tvalidateTrigger: ['change', 'blur'],\n\t\t\t\t\trules: [\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\twhitespace: true,\n\t\t\t\t\t\t\tmessage: 'Please input passenger\\'s name or delete this field.',\n\t\t\t\t\t\t},\n\t\t\t\t\t],\n\t\t\t\t},\n\t\t\t]"}],staticStyle:{"width":"60%","margin-right":"8px"},attrs:{"placeholder":"passenger name"}}),(_vm.form.getFieldValue('keys').length > 1)?_c('a-icon',{staticClass:"dynamic-delete-button",attrs:{"type":"minus-circle-o","disabled":_vm.form.getFieldValue('keys').length === 1},on:{"click":function () { return _vm.remove(k); }}}):_vm._e()],1)}),_c('a-form-item',_vm._b({},'a-form-item',_vm.formItemLayoutWithOutLabel,false),[_c('a-button',{staticStyle:{"width":"60%"},attrs:{"type":"dashed"},on:{"click":_vm.add}},[_c('a-icon',{attrs:{"type":"plus"}}),_vm._v(" Add field ")],1)],1),_c('a-form-item',_vm._b({},'a-form-item',_vm.formItemLayoutWithOutLabel,false),[_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" Submit ")])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }